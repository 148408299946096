.inspirobotPage {
  text-align: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: left;
  overflow: hidden;
  margin: 20px 0;
}

.imageContainer img {
  max-width: 100%;
  max-height: 500px;
  display: block;
}

.generateButton {
  margin-top: 10px;
  padding: 14px 40px;
  border: 8px solid var(--primary-color);
  background-color: var(--secondary-color);
  color: var(--text-color);
  width: 200px;
}

.generateButton:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}


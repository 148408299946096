.navbar{
  background-color: var(--secondary-color);
  font-family: Arial, Helvetica, sans-serif;
	margin-bottom: 0;
	display: flex;
	justify-content: space-between;
  padding: 0 1rem;
}

.linkContainer{
  display: flex;
}

.link {
  float: left;
  color: var(--text-color);
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
}

.link:hover {
  background-color: var(--hover-color);
  color: black;
}

.footer {
  background-color: var(--secondary-color, #333);
  color: var(--text-color, #fff);
  text-align: center;
  padding: 20px;
  margin-top: auto;
}

.content {
  margin: auto;
}

.projectsPage {
    background: var(--secondary-color);
    display: flex; 
    flex-direction: column;
}

.mainContainer {
    background: var(--primary-color);
    padding-top: 20px;
    padding-bottom: 20px;
    flex-grow: 1;
  }

a {
    color: var(--text-color);
  }
  
a:hover, a:active, a:visited {
      color: var(--text-color) ;
  }
.card {
	background-color: var(--card-color);
  border-radius: 10px;
  padding: 30px;
  margin: 2%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.cardTitle {
  color: var(--text-color);
  font-size: xx-large;
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 15px;
}

.cardContent {
  color: var(--text-color);
  font-size: large;
  line-height: 1.6;
  text-align: justify;
  padding: 0 1em;
}

.indent {
  padding-left: 20px;
}

.headerWithImage, .header, .headerCentered {
  display: flex;
  align-items: center;
}

.headerCentered {
  justify-content: center;
}

.cardImage {
  width: 50px;
  height: 50px;
  order: 2;
  margin-right: 10px;
}

.textContent {
  flex-grow: 1;
  text-align: justify;
  padding: 0 1em;
}

html, body, #root, .App{
  height: 100%;
  margin: 0;
  padding: 0;
}

.App{
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  background: var(--primary-color);
}
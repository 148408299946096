.contactPage{
    background: var(--secondary-color);
    display: flex; 
    flex-direction: column;
}

.mainContainer {
    background: var(--primary-color);
    padding-top: 20px;
    padding-bottom: 20px;
    flex-grow: 1;
  }

  a {
    color: var(--text-color);
  }
  
a:hover, a:active, a:visited {
      color: var(--text-color) ;
  }

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: left;
  }
  
  .list li {
    display: inline-block;
    margin-right: 10px;
  }
  
  .list li:last-child {
    margin-right: 0;
  }
  
  .list img {
    width: 48px;
    height: 48px;
    vertical-align: middle;
  }